import axios from 'axios';
import { IAddress } from 'src/common/types/AddressType';

const localizationService = {
  getAddressByCordinates: async (
    latitude: number,
    longitude: number,
  ): Promise<IAddress> => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    const response = await axios.get(url);
    const address = response.data['address'];

    return {
      city: address['city'],
      cityDistrict: address['city_district'],
      country: address['country'],
      countryCode: address['country_code'],
      county: address['county'],
      municipality: address['municipality'],
      postcode: address['postcode'],
      region: address['region'],
      residential: address['residential'],
      road: address['road'],
      state: address['state'],
      stateDistrict: address['state_district'],
      suburb: address['suburb'],
    };
  },
};

export default localizationService;
