// Modules
import React from 'react';
import Head from 'next/head';

// Types
import { ICycle, IPage } from 'src/common/types/CatalogType';

// Services
import catalogService from 'src/services/catalogService';

// Components
import LandingPage from 'src/components/templates/LandingPage';

// Brand content
import content from 'src/common/content';

// Hooks
import { useGeoLocation } from 'src/common/hooks/useGeoLocation';

interface IHome {
  catalogCycle: ICycle;
  catalogCover: IPage;
  pdfSrc: string;
}

const Home = ({ catalogCycle, catalogCover, pdfSrc }: IHome) => {
  useGeoLocation();

  return (
    <>
      <Head>
        <link rel="canonical" href={`${content.urlBase}/`} />
      </Head>
      <LandingPage
        catalogCycle={catalogCycle}
        catalogCover={catalogCover}
        pdfSrc={pdfSrc}
      />
    </>
  );
};

export async function getStaticProps() {
  const res = await catalogService.getCatalog();

  return {
    props: {
      catalogCycle: res.cycle,
      catalogCover: res.pages[0],
      pdfSrc: res.pdf,
    },
  };
}

export default Home;
