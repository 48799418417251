import { useEffect } from 'react';
import slugify from 'react-slugify';
import { useRouter, NextRouter } from 'next/router';
import localizationService from 'src/services/localization/localizationService';
import { IAddress } from 'src/common/types/AddressType';
import brandContent from 'src/common/content';
import sendGeolocationEvent from 'src/services/googleAnalytics/helpers/geolocation/sendGeolocationEvent';

export const convertPermissionStatusToLabel = (status: string): string =>
  status === 'granted' ? 'sim' : 'não';

export const getUFPathname = (
  address: IAddress,
  content: typeof brandContent = brandContent,
) => {
  const city = address.city || address.cityDistrict;
  const slugState = slugify(address.state);
  const uf = content.getUFByStateSlug(slugState, city);

  return `/${uf}`;
};

export const navigateToUF =
  (navigate: NextRouter['push']) => async (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    const address = await localizationService.getAddressByCordinates(
      latitude,
      longitude,
    );
    const pathToNavigate = getUFPathname(address);
    navigate(pathToNavigate);
  };

export const getLocation = (router: NextRouter) => {
  if (typeof window.navigator === 'object') {
    window.navigator.geolocation.getCurrentPosition(navigateToUF(router.push));
  }
};

export const listenPermissionStatus = (
  sendPermissionGAEvent: (status: string) => void,
) => {
  if ('permissions' in window.navigator) {
    window.navigator.permissions
      .query({ name: 'geolocation' })
      .then(function (geolocationPermission) {
        geolocationPermission.addEventListener('change', function () {
          const status = convertPermissionStatusToLabel(
            geolocationPermission.state,
          );
          sendPermissionGAEvent(status);
        });
      });
  }
};

export const useGeoLocation = () => {
  const router = useRouter();

  useEffect(() => {
    listenPermissionStatus(sendGeolocationEvent.sendPermission);
    getLocation(router);
  }, []);
};
