import sendGoogleAnalytics from 'src/services/googleAnalytics/sendGoogleAnalytics';

const sendGeolocationEvent = {
  sendPermission: (eventLabel: string) => {
    sendGoogleAnalytics.event({
      eventAction: 'clique:permissao-localizacao',
      eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
      eventLabel,
    });
  },
};

export default sendGeolocationEvent;
